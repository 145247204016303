import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import { Trans, useTranslation } from "react-i18next"
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa"
import { StaticImage } from "gatsby-plugin-image"
import bg from "../images/staticsvgback.svg"

const How = () => {
  const { t } = useTranslation()
  return (
    <Layout>
      <Seo title={t("How")} />
      <div
        className="bg-light gap-5 d-flex flex-column text-center"
        style={{
          background: `url(${bg}) no-repeat fixed center`,
          backgroundSize: "contain",
        }}
      >
        <div className="p-5">
          <h1 className="display-2 p-5">
            <Trans>How</Trans>
          </h1>
          <div className="p-5">
            <FaQuoteLeft className="text-warning display-6" />
            <span className="lead font-italic mx-3 display-6">
              <Trans>Quick reliable easy practical</Trans>
            </span>
            <FaQuoteRight className="text-warning display-6" />
          </div>
        </div>
        <div className="bg-dark text-light shadow py-1 py-md-5">
          <div className="container ">
            <div
              className="align-items-center px-2 px-md-5 py-5
             d-flex flex-column justify-content-center align-items-center
            "
            >
              <div
                className=" border border-1 shadow-lg p-2 p-md-5 row"
                style={{ borderRadius: "1rem" }}
              >
                <div className="col-12 col-md-6">
                  <StaticImage
                    src="../images/rental.jpg"
                    quality={95}
                    formats={["auto", "webp", "avif"]}
                    alt="Mobile View"
                    style={{ objectFit: "contain" }}
                    className="rounded border-1 border-light
                    border shadow"
                  />
                </div>
                <div
                  className="col-md-6 col-12 
                d-flex flex-column justify-content-center align-items-center"
                >
                  <p className="mt-5">
                    <Trans>
                      uhappy? provides you the tools to create and customize the
                      layout of each survey according to the product of interest
                      and then process and evaluate the results
                    </Trans>
                  </p>
                </div>
              </div>

              <div className="row text-dark py-5">
                <div className=" col-lg-6 p-4">
                  <div
                    className="border shadow-lg p-5 h-100 bg-warning d-flex align-items-center justify-content-center flex-column"
                    style={{ borderRadius: "1rem" }}
                  >
                    <StaticImage
                      src="../images/logohigh.jpg"
                      quality={95}
                      formats={["auto", "webp", "avif"]}
                      alt="Mobile View"
                      className="rounded border-1 border-dark
                       border mb-5 shadow"
                      style={{ objectFit: "contain" }}
                    />
                    <li>
                      <Trans>
                        include company and product logos as necessary
                      </Trans>
                    </li>
                  </div>
                </div>

                <div className=" col-lg-6 p-4">
                  <div
                    className="border shadow-lg p-5 h-100 bg-light d-flex align-items-center justify-content-center flex-column"
                    style={{ borderRadius: "1rem" }}
                  >
                    <StaticImage
                      src="../images/style.jpg"
                      quality={95}
                      formats={["auto", "webp", "avif"]}
                      alt="Mobile View"
                      className="rounded border-1 border-dark 
                      border mb-5 shadow"
                      style={{ objectFit: "contain" }}
                    />
                    <li>
                      <Trans>
                        create background colors fonts and overall survey style
                      </Trans>
                    </li>
                  </div>
                </div>

                <div className=" col-lg-6 p-4">
                  <div
                    className="border shadow-lg p-5 h-100 bg-light d-flex align-items-center justify-content-center flex-column"
                    style={{ borderRadius: "1rem" }}
                  >
                    <StaticImage
                      src="../images/avalability.jpg"
                      quality={95}
                      formats={["auto", "webp", "avif"]}
                      alt="Mobile View"
                      className="rounded border-1 border-dark
                       border mb-5 shadow"
                      style={{ objectFit: "contain" }}
                    />
                    <li>
                      <Trans>
                        determine frequency and availability of uhappy? surveys
                      </Trans>
                    </li>
                  </div>
                </div>

                <div className=" col-lg-6 p-4">
                  <div
                    className="border shadow-lg p-5 h-100 bg-warning d-flex align-items-center justify-content-center flex-column"
                    style={{ borderRadius: "1rem" }}
                  >
                    <StaticImage
                      src="../images/types.jpg"
                      quality={95}
                      formats={["auto", "webp", "avif"]}
                      alt="Mobile View"
                      className="rounded border-1 border-dark 
                      border mb-5 shadow"
                      style={{ objectFit: "contain" }}
                    />
                    <li>
                      <Trans>
                        the answers can be Y/N, multiple choice or text
                      </Trans>
                    </li>
                  </div>
                </div>

                <div className=" col-lg-6 p-4">
                  <div
                    className="border shadow-lg p-5 h-100 bg-warning d-flex align-items-center justify-content-center flex-column"
                    style={{ borderRadius: "1rem" }}
                  >
                    <StaticImage
                      src="../images/notifications.jpg"
                      quality={95}
                      formats={["auto", "webp", "avif"]}
                      alt="Mobile View"
                      className="rounded border-1 border-dark
                       border mb-5 shadow"
                      style={{ objectFit: "contain" }}
                    />
                    <li>
                      <Trans>
                        immediate feedback of customer dissatisfaction
                      </Trans>
                    </li>
                  </div>
                </div>

                <div className=" col-lg-6 p-4">
                  <div
                    className="border shadow-lg p-5 h-100 bg-light d-flex align-items-center justify-content-center flex-column"
                    style={{ borderRadius: "1rem" }}
                  >
                    <StaticImage
                      src="../images/stats.jpg"
                      quality={95}
                      formats={["auto", "webp", "avif"]}
                      alt="Mobile View"
                      className="rounded border-1 border-dark
                       border mb-5 shadow"
                      style={{ objectFit: "contain" }}
                    />
                    <li>
                      <Trans>
                        statistical analysis of results in the forms of pie and
                        excel graphs
                      </Trans>
                    </li>
                  </div>
                </div>

                <div className=" col-lg-6 p-4">
                  <div
                    className="border shadow-lg p-5 h-100 bg-light d-flex align-items-center justify-content-center flex-column"
                    style={{ borderRadius: "1rem" }}
                  >
                    <StaticImage
                      src="../images/infinity.png"
                      quality={95}
                      formats={["auto", "webp", "avif"]}
                      alt="infinity"
                      className=" mb-5"
                      style={{ objectFit: "contain" }}
                    />
                    <li>
                      <Trans>
                        depending on package selected you can run from 20 to
                        infinite uhappy? surveys simultaneously
                      </Trans>
                    </li>
                  </div>
                </div>

                <div className=" col-lg-6 p-4">
                  <div
                    className="border shadow-lg p-5 h-100 bg-warning d-flex align-items-center justify-content-center flex-column"
                    style={{ borderRadius: "1rem" }}
                  >
                    <StaticImage
                      src="../images/datakati.png"
                      quality={95}
                      formats={["auto", "webp", "avif"]}
                      alt="data"
                      className=" mb-5"
                      style={{ objectFit: "contain" }}
                    />

                    <li>
                      <Trans>
                        data base creation by category, such as, age, gender,
                        area if you include the appropriate fields, through
                        which you can target your audience with customized
                        advertisement
                      </Trans>
                    </li>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default How

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
